import StripePaymentMethod from "./StripePaymentMethod";

export default class StripeApplePayPaymentMethod extends StripePaymentMethod {
  async isAvailable() {
    if (this.isMac()) return false;

    try {
      const result = await this.paymentRequest.canMakePayment();
      if (!result) {
        console.log("no wallet available");
        return false;
      }

      return result.applePay;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.paymentRequest.show();
  }

  isMac() {
    return navigator.platform.includes("Mac");
  }
}
