import PaymentMethodFactory from './payment_methods/PaymentMethodFactory';

export default class PaymentPage {
  constructor(optionsForm) {
    this.optionsForm = optionsForm;
  }

  async orchestratePaymentOptions() {
    const radioButtons = this.optionsForm.find('input[type="radio"]');

    const availablePaymentMethods = (
      await Promise.all(
        radioButtons.map(async (_index, element) => this.handlePaymentMethodAvailability(element))
      )
    ).filter(paymentMethod => !!paymentMethod);

    if(availablePaymentMethods.length === 1) {
      availablePaymentMethods[0].showForm();
    } else {
      $('.payment-options').removeClass('hidden');
    }
  }

  async handlePaymentMethodAvailability(element) {
    const payment_method = PaymentMethodFactory.for(element)
    if (await payment_method.isAvailable()) {
      payment_method.showOption();
      payment_method.bindFormMethods();

      return payment_method;
    }
  }
}
