import BumperBNPLPaymentMethod from "./BumperBNPLPaymentMethod";
import StripeApplePayPaymentMethod from "./StripeApplePayPaymentMethod";
import StripeCardPaymentMethod from "./StripeCardPaymentMethod";
import AdyenKlarnaPaymentMethod from "./AdyenKlarnaPaymentMethod";

export default class PaymentMethodFactory {
  static for(paymentOption) {
    const option = $(paymentOption);
    const name = option.attr("value");
    const form = $(`#${name}-form`);

    const klass = {
      "stripe-apple-pay": StripeApplePayPaymentMethod,
      "stripe-card": StripeCardPaymentMethod,
      "bumper-bnpl": BumperBNPLPaymentMethod,
      "adyen-klarna": AdyenKlarnaPaymentMethod,
    }[name];
    return new klass(name, option, form);
  }
}
