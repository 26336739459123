import $ from 'jquery'
import $Form from '../common/forms'
import PaymentPage from './PaymentPage'

$(async function () {
  var $paymentOptionsForm = $('#payment-options-form');
  if ($paymentOptionsForm.length) {
    const paymentPage = new PaymentPage($paymentOptionsForm);
    paymentPage.orchestratePaymentOptions();
  }
});
