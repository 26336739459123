import $Form from "../../common/forms";
import $ from "jquery";

export default class NewCardStripeForm {
  static style = {
    base: {
      fontFamily: '"Proxima Nova", "Helvetica", Arial, sans-serif',
      lineHeight: "50px",
      fontSize: "16px",
      paddingBottom: "7px",
      "::placeholder": {
        color: "#a4a4a4",
      },
    },
    invalid: {
      color: "inherit",
    },
  };

  constructor(stripe, form) {
    this.stripe = stripe;
    this.form = form;
  }

  get cardName() {
    return this.form.find("#card-name").val();
  }

  isStripeAvailable() {
    return this.form.find(".new-card-fields").length > 0;
  }

  buildCardFormElements() {
    const elements = this.stripe.elements({ locale: "en" });
    const style = NewCardStripeForm.style;

    this.cardNumberElement = elements.create("cardNumber", { style: style, placeholder: "e.g. 1234 1234 1234 1234" });
    this.cardExpiryElement = elements.create("cardExpiry", { style: style });
    this.cardCvcElement = elements.create("cardCvc", { style: style });

    this.cardNumber = this.form.find("#card-number-element")[0];
    this.cardExpiry = this.form.find("#card-expiry-element")[0];
    this.cardCvc = this.form.find("#card-cvc-element")[0];

    this.mountCardFormElements();
  }

  mountCardFormElements() {
    if (this.isStripeAvailable()) {
      this.cardNumberElement.mount(this.cardNumber);
      this.cardExpiryElement.mount(this.cardExpiry);
      this.cardCvcElement.mount(this.cardCvc);
    }
  }

  handleElementChange(elementContainer, state) {
    $Form.removeError(elementContainer, "stripe", $Form.getErrorID(elementContainer));
    elementContainer.data("stripeChangeState", state);
  }

  bindFormMethods() {
    this.cardNumberElement.on("change", (e) => this.handleElementChange($(this.cardNumber), e));
    this.cardExpiryElement.on("change", (e) => this.handleElementChange($(this.cardExpiry), e));
    this.cardCvcElement.on("change", (e) => this.handleElementChange($(this.cardCvc), e));
  }
}
