import $Form from "../../common/forms";

export default class PaymentMethod {
  constructor(name, option, form) {
    this.option = option;
    this.name = name;
    this.form = form;
  }

  showForm() {
    this.form.siblings().hide();
    this.form.show();
  }

  showOption() {
    this.option.parent().removeClass("hidden");
  }

  bindFormMethods() {
    this.option.on("click", this.showForm.bind(this));
    this.form.on("submit", this.onSubmit.bind(this));
  }

  async isAvailable() {
    return false;
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    $Form.postAjax(this.form, this.form.find("input[type=submit]"));
  }
}
