import StripePaymentMethod from "./StripePaymentMethod";
import NewCardStripeForm from "./NewCardStripeForm";
import $Form from "../../common/forms";

export default class StripeCardPaymentMethod extends StripePaymentMethod {
  constructor(name, option, form) {
    super(name, option, form);

    this.cardNumberContainer = this.form.find("#card-number-element");
    this.cardExpiryContainer = this.form.find("#card-expiry-element");
    this.cardCvcContainer = this.form.find("#card-cvc-element");
    this.newCardSubForm = this.form.find(".stripe-saved-cards-new-card-sub-form");
    this.newCardRadio = this.form.find("#new-card-radio");
    this.inputs = this.form.find("input[name=payment_method_id]");

    this.newCardStripeForm = new NewCardStripeForm(this.stripe, form);
    this.newCardStripeForm.buildCardFormElements();
  }

  bindFormMethods() {
    super.bindFormMethods();

    this.newCardStripeForm.bindFormMethods();
    this.form.on("custom-validation", this.onCustomValidation.bind(this));
    this.inputs.on("change", this.onPaymentMethodRadioChanged.bind(this));
  }

  isAvailable = async () => {
    return true;
  };

  isNewCardFormVisible() {
    return this.form.find("#stripe-new-card-fields").css("display") !== "none";
  }

  onCustomValidation(e, noOfErrors) {
    let errors = noOfErrors;
    const containers = [this.cardNumberContainer, this.cardExpiryContainer, this.cardCvcContainer];

    if (this.isNewCardFormVisible()) {
      containers.forEach((container) => {
        const state = container.data("stripeChangeState");
        if (!state || state.empty || state.error) {
          errors += 1;
          $Form.showError(this.form, container, "stripe", container, state?.error?.message);
        }
      });
    }

    this.form.trigger("custom-validation-callback", [errors]);
  }

  onPaymentMethodRadioChanged() {
    if (this.newCardRadio.is(":checked")) {
      this.newCardSubForm.slideDown();
    } else {
      this.newCardSubForm.slideUp();
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!e.isTrigger) {
      $Form.validate(this.form);
      return;
    }

    if (this.isNewCardFormVisible()) {
      this.stripe
        .confirmCardSetup(this.stripeSecret(), {
          payment_method: {
            card: this.newCardStripeForm.cardNumberElement,
            billing_details: {
              name: this.newCardStripeForm.cardName,
            },
          },
        })
        .then((result) => {
          this.responseHandler(result);
        });
    } else {
      return super.onSubmit(e);
    }
  }

  updateCardRadioValue(result) {
    this.newCardRadio.val(result.setupIntent.payment_method);
  }
}
