import PaymentMethod from "./PaymentMethod";
import { AdyenCheckout, Klarna } from "@adyen/adyen-web";
import "@adyen/adyen-web/styles/adyen.css";
import $Form from "../../common/forms";

export default class AdyenKlarnaPaymentMethod extends PaymentMethod {
  constructor(name, option, form) {
    super(name, option, form);

    this.sessionId = $("meta[name=AdyenSessionId]").attr("content");
    this.sessionData = $("meta[name=AdyenSessionData]").attr("content");
    this.clientKey = $("meta[name=AdyenClientKey]").attr("content");
    this.environment = $("meta[name=AdyenEnvironment]").attr("content");
  }

  async bindFormMethods() {
    super.bindFormMethods();

    const checkout = await AdyenCheckout(this.checkoutConfiguration);
    const klarna = new Klarna(checkout, this.klarnaConfiguration).mount("#adyen-klarna-component");

    klarna.submit();
  }

  get klarnaConfiguration() {
    return {
      useKlarnaWidget: true,
      type: "klarna_account",
    };
  }

  get checkoutConfiguration() {
    return {
      session: {
        id: this.sessionId,
        sessionData: this.sessionData,
      },
      environment: this.environment,
      amount: {
        value: 0,
        currency: "GBP",
      },
      locale: "en-US",
      countryCode: "GB",
      clientKey: this.clientKey,
      showPayButton: false,
      onPaymentCompleted: this.onPaymentCompleted.bind(this),
      onPaymentFailed: this.onPaymentFailed.bind(this),
    };
  }

  onPaymentCompleted(result, component) {
    this.form.submit();
  }

  onPaymentFailed() {
    $Form.showGeneralError(this.form, "klarna", "Error");
  }

  async isAvailable() {
    return true;
  }
}
