import PaymentMethod from "./PaymentMethod";
import $Form from "../../common/forms";
import $ from "jquery";

export default class StripePaymentMethod extends PaymentMethod {
  constructor(name, option, form) {
    super(name, option, form);

    const stripePublishableKey = $("meta[name=stripe-publishable-key]").attr("content");
    this.stripe = Stripe(stripePublishableKey);
    if (!this.stripe) {
      $Form.showGeneralError(this.form, "stripe", "No secure connection");
      return;
    }

    this.paymentRequest = this.stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: "Quote total amount",
        amount: parseInt($("meta[name=authorization-total]").attr("content")),
        pending: true,
      },
      requestPayerName: true,
    });

    this.paymentRequest.on("cancel", this.onPaymentRequestCancel.bind(this));
    this.paymentRequest.on("paymentmethod", this.onPaymentRequestPaymentMethod.bind(this));
    this.submitButton = this.form.find("input[type=submit]");
  }

  onPaymentRequestCancel() {
    $Form.renableButton(this.submitButton);
  }

  onPaymentRequestPaymentMethod(e) {
    this.stripe.confirmCardSetup(this.stripeSecret(), { payment_method: e.paymentMethod.id }).then((result) => {
      this.responseHandler(result);
    });
  }

  stripeSecret() {
    return this.submitButton.data("secret");
  }

  responseHandler(result) {
    if (result.error) {
      $Form.showGeneralError(this.form, "stripe", result.error.message);
      $Form.renableButton(this.submitButton);
    } else {
      if (result.setupIntent) {
        this.form.find("input#payment_method_id").val(result.setupIntent.payment_method);
        this.updateCardRadioValue(result);
      }
      $Form.postAjax(this.form, this.submitButton);
    }
  }

  updateCardRadioValue(result) {}
}
